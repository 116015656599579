<template>
    <div>
      <v-card class="mx-auto" max-width="500px">
        <v-list>
          <v-list-item-group>
            <v-list-item 
              v-for="(item, i) in kebabItems" 
              :key="i" 
              @click="triggerEvent(item.text)"
            >
              <v-list-item-content>
                <v-list-item-title
                  v-text="item.text"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </div>
  </template>
  
  
  <script type="text/babel">
  import { EventBus } from "../../../EventBus.js";
  
  export default {
    name: "nationalAccountUnitsKebabView",
    props: 
       ['contact'],
    
    data() {
      return {
        kebabItems: [
            {
                text:"Move Out",
                event:"moveout",

            }
        ]
      }
    },
    methods: {
      triggerEvent(text) {
       this.$emit('close');
                    EventBus.$emit('move_out', {
                        property_id: this.params.data.property_id,
                        contact: this.contact ,
                        lease_id: this.params.data.lease_id,
                        unit_id: this.params.data.unit_id
                    });
      },
    },
  };
  </script>
  