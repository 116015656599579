<template>
    <hb-report report_type="national_account_unit_list" :default_search_filters="search_filter" :contact="contact"
        :actions_panel="['advanced', 'filters', 'export', 'columns', 'save']" show_views right_click
        row_click show_search  :show_default_report_option="true"
        :grid-conf="gridConf"         
        >    
    </hb-report>
</template>
<script>
import HbReport from '../../assets/HummingbirdReportViewer.vue';
import nationalAccountUnitsKebab from './nationalAccountUnitsKebabFilter.vue'
export default {
    name: "NationalAccountUnitListView",
    props: ['contact'],
    data() {
        return {

        };
    },
    components: {
        HbReport
    },
    computed: {
        search_filter() {
            return [
                {
                    id: "contact_id",
                    value: this.contact.id,
                },
            ];
        },
        gridConf() {
      return {
        props: {
          ...( {
            BIHelpers: {
              kebab: {
                enabled: true,
                icon: "mdi-dots-vertical",
                component: {
                  definition: nationalAccountUnitsKebab,
                  props: {
                    contact: this.contact,
                  },
                },
                action: "click",
              },
            },
          }),
      
          defaultColDef: {
            floatingFilter: false,
            wrapHeaderText: true,
            autoHeaderHeight: true,
          },
        },
  
      };
    },
    }
}

</script>
<style scoped></style>